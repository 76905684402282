'use strict';
class SocketService {
  /* @ngInject */
  constructor(jwtSessionService) {
    this.jwtSessionService = jwtSessionService;
  }

  /**
   * Open socket connection
   *
   * @param {string} path
   * @param {string} namespace
   * @param {object} query
   * @returns {object} socket
   */
  open(path, namespace, query) {
    return io(namespace, {
      path,
      transports: ['websocket', 'polling'],
      reconnect: false,
      auth: { token: this.jwtSessionService.jwt() },
      query,
    });
  }

  /**
   * Open socket connection on referentiel-financement
   *
   * @param {string} namespace
   * @param {object} query
   * @returns {object} socket
   */
  openOnReferentielFinancement(namespace, query) {
    return this.open('/referentiel-financement/api/websocket', namespace, query);
  }

  /**
   * Open socket connection for locking demande
   *
   * @param {string} tenantId
   * @param {string} demandeId
   * @returns {object} socket
   */
  openForLockingDemande(tenantId, demandeId) {
    const query = { demandeId };
    const namespace = `/${tenantId}/demandes-financement/lock`;
    return this.openOnReferentielFinancement(namespace, query);
  }

  /**
   * Close socket connection
   *
   * @param {object} socket
   * @returns {void}
   */
  close(socket) {
    socket.disconnect();
  }
}
SocketService.$inject = ['jwtSessionService'];

/**
 * @name socketService
 * @class SocketService
 * @description Helpers used to handle web sockets
 */
angular.module('common.services').service('socketService', SocketService);
