'use strict';
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurFamilleController', depotSimpleDemandeurFamilleController);

depotSimpleDemandeurFamilleController.$inject = [
  '$scope',
  '$rootScope',
  '$q',
  '$httpParamSerializer',
  'Tiers',
  'tiersService',
  'tiersFacts',
  'configuration',
  'jwtSessionService',
  '$sce',
  'StoreService',
  '$log',
  'alertsService',
  '$window',
];

/**
 *
 * @param {object} $scope
 * @param {object} $rootScope
 * @param {object} $q
 * @param {object} $httpParamSerializer
 * @param {object} Tiers
 * @param {object} tiersService
 * @param {object} tiersFacts
 * @param {object} configuration
 * @param {object} jwtSessionService
 * @param {object} $sce
 * @param {object} StoreService
 * @param {object} $log
 * @param {object} alertsService
 * @param {object} $window
 */
function depotSimpleDemandeurFamilleController(
  $scope,
  $rootScope,
  $q,
  $httpParamSerializer,
  Tiers,
  tiersService,
  tiersFacts,
  configuration,
  jwtSessionService,
  $sce,
  StoreService,
  $log,
  alertsService,
  $window
) {
  'use strict';

  $scope.loaded = false;
  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur || {};
  $scope.cleanNavigate();
  // Manually set $error to form to disable save button at init
  // If we already have a valid tiers we should receive a message from iframe to clear $error
  $scope.depotForm.$error = { invalidTiers: {} };

  let publicSettingsTiers;
  tiersService.getPublicSettingsTiers().then((publicSettings) => {
    publicSettingsTiers = publicSettings;
  });

  let currentDemandeur = StoreService.demandeur.get();

  /**
   * Get metadata of the demande
   *
   * @returns {object}
   */
  function getDemandeMetadata() {
    return $scope.aide?.history?.begin?.metadata ?? null;
  }

  /**
   * Returns the referentiel-tiers iframe url for siret verification
   *
   * @param {object[]} familles
   * @param {object} gestionRattachementCompte
   * @param {object} demandeur
   * @returns {string}
   */
  function getVerificationSiretIframeUrl(familles, gestionRattachementCompte, demandeur) {
    const teleservice = $scope.aide?.teleservice?.expand?.reference;
    const jwtKey = jwtSessionService.getJwtKey();
    const queryParams = $httpParamSerializer({
      theme: 'demandeur',
      usagers: true,
      ref: demandeur?.reference,
      boutonSuivant: false,
      jwtKey,
      familles,
      gestionRattachementCompte,
      teleservice,
      adresseEtrangere: true,
      nsResources: 'demandeur-famille',
      searchType: getDemandeMetadata().searchTypeDemandeur,
    });

    const url = `${configuration.tiers.ux}#/verificationsiret?${queryParams}`;
    return $sce.trustAsResourceUrl(url);
  }

  /**
   * Get iframe element
   *
   * @returns {object}
   */
  function getIframeElement() {
    return angular.element('#verification-siret-iframe')?.[0]?.contentWindow;
  }

  /**
   * Manage the messages we receive from child frames
   *
   * @param {object} msgEvent
   * @returns {void}
   */
  function receiveMessageEvent(msgEvent) {
    if (!$scope.loaded) {
      // controller is loaded, we can hide the spinner
      $scope.loaded = true;
      // Resize iframe
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#verification-siret-iframe'
      );
    }

    $scope.mutitiersFacts = tiersFacts.facts.multitiers;

    const data = msgEvent.data;
    if (data.action !== 'tiersValidation') {
      return;
    }

    // Block navigation in case of rattachement on a tiers on which the user is already linked
    $scope.navigate.block = data?.tiers?.isUserAlreadyLinked;

    $scope.navigate.unlockNavigation();
    $scope.tiersValidation = data;

    // add/reset error in the form to disable/enable the save button
    $scope.depotForm.$error = data.valid ? {} : { invalidTiers: {} };

    // Add search type to global meta.
    // We will need to remove those at validation time.
    getDemandeMetadata().searchTypeDemandeur = data.searchType;

    /**
     * If tiers's famille is TIERS_PARTICULIER or TIERS_ENTREPRENEUR_INDIVIDUEL
     * navigation should be stopped because a user can only be linked to one of those
     *
     * ! Sharing mode
     * In sharing mode, user is able to declare a tiers PHYSIQUE even if user already is one
     * because the new tiers PHYSIQUE is not for himself.
     */
    if (data.tiers && !$scope.isAccessedThroughSharing) {
      if (
        data.tiers?.famille?.expand?.typeFamille === 'TIERS_PARTICULIER' &&
        $scope.mutitiersFacts.hasOneTiersParticulier
      ) {
        $scope.navigate.lockNavigation();
        $scope.depotForm.$error = { invalidTiers: {} };
        // Alert is managed through verification-siret iframe
        getIframeElement().postMessage({ action: 'invalid-tiers-particulier' }, '*');
      }

      if (
        data.tiers?.famille?.expand?.typeFamille === 'TIERS_ENTREPRENEUR_INDIVIDUEL' &&
        $scope.mutitiersFacts.hasOneTiersEI
      ) {
        $scope.navigate.lockNavigation();
        $scope.depotForm.$error = { invalidTiers: {} };
        // Alert is managed through verification-siret iframe
        getIframeElement().postMessage({ action: 'invalid-tiers-ei' }, '*');
      }
    }

    // override demandeur data if the iframe returns a valid tiers
    // (may return valid message without a tiers to avoid overriding demandeur on load)
    if (data.valid && data.tiers) {
      $scope.tiersFound = data.tiers;

      const apiEntrepriseActive = publicSettingsTiers.apiEntreprise?.active;
      const updatedDemandeurFields = { SIRET: data.tiers.SIRET };

      const situations = currentDemandeur?.situations;
      const situationPrincipale = situations?.find(({ principale }) => principale);
      const isEtranger = situationPrincipale?.etrangere;

      const thematiquesLiees = currentDemandeur?.thematiquesLiees ?? {};

      if (!thematiquesLiees.association) {
        thematiquesLiees.association = {};
      }
      const newThematiqueAsso = data.tiers?.thematiquesLiees?.association ?? {};

      const newAssoInfos = newThematiqueAsso.expand || {};
      const oldAssoInfos = thematiquesLiees.association ?? {};
      // Checks if the returned tiers already has an administrator account
      if (data.origin !== 'referentiel') {
        if (apiEntrepriseActive || isEtranger) {
          // do not set fields as undefined if they did not change
          // otherwise the thematique would not be empty (it would have the key with an undefined value) and we would try to update it
          if (oldAssoInfos.codeRNA || newAssoInfos.codeRNA) {
            oldAssoInfos.codeRNA = newAssoInfos.codeRNA;
          }

          _.assign(updatedDemandeurFields, {
            thematiquesLiees,
          });
        }

        if (apiEntrepriseActive) {
          if (oldAssoInfos.dateModificationRNA || newAssoInfos.dateModificationRNA) {
            oldAssoInfos.dateModificationRNA = newAssoInfos.dateModificationRNA;
          }
          if (oldAssoInfos.datePublicationJO || newAssoInfos.datePublicationJO) {
            oldAssoInfos.datePublicationJO = newAssoInfos.datePublicationJO;
          }
          if (oldAssoInfos.descriptionActivites || newAssoInfos.descriptionActivites) {
            oldAssoInfos.descriptionActivites = newAssoInfos.descriptionActivites;
          }
          _.assign(updatedDemandeurFields, {
            raisonSociale: data.tiers.raisonSociale,
            referenceAdministrative: data.tiers.referenceAdministrative,
            situations: data.tiers.situations,
            NAF: data.tiers.NAF,
            NAFNiv732: data.tiers.NAFNiv732,
            externalData: data.tiers.externalData,
            enseigne: data.tiers.enseigne,
            civilite: data.tiers.civilite,
            individu: data.tiers.individu,
            thematiquesLiees,
          });
        } else {
          const currentSituationPaysCode = situationPrincipale?.geographique?.Pays?.code;
          const updatedSituationPaysCode = data.tiers.situations?.[0].geographique?.Pays?.code;
          const doublonFound = data.tiers.id;

          // reset situations when domiciliation changes if api entreprise is not active
          if (currentSituationPaysCode !== updatedSituationPaysCode || !doublonFound) {
            updatedDemandeurFields.situations = data.tiers.situations;
          }
        }
      }

      updatedDemandeurFields.sansSIRET = data.tiers?.sansSIRET;

      // get the expanded famille from mdm
      // (the expanded version from iframe is missing some informations needed for the depot)
      updatedDemandeurFields.famille = _.find(
        $scope.mdm.famillestiers.array,
        (famille) => famille.href === _.get(data.tiers, 'famille.href')
      );

      // add famille pieces to the tiers
      // (not done by the referentiel-tiers middleware because the tiers is created without any famille)
      tiersService.addFamillePiecesToTiers(updatedDemandeurFields);

      const newDemandeur = new Tiers(_.assign({}, currentDemandeur, updatedDemandeurFields));

      // call the function of famille change to update the tiers
      // If sharing mode, set user as null to avoid pre-filled fields
      const demandeurUser = $scope.isAccessedThroughSharing ? null : $scope.currentUser;
      tiersService.changeFamilleTiers(newDemandeur, demandeurUser, $scope.mdm, $scope.masterdata);
      currentDemandeur = StoreService.demandeur.set(newDemandeur);
      $scope.aide.demandeur.expand = currentDemandeur;
    }

    // Manually set $error to form to disable/enable save button and then use $scope.$apply()
    // to force angular to trigger watcher because this function isn't called from angularjs
    $scope.$apply();
  }

  // The promises created during the controller life cycle,
  const promises = [];

  // Get the list of familles references in a string
  const restrictionFamillesTiers = $scope.pageOptions.familles
    ?.map((famille) => famille.famille.href.split('/').pop())
    .join(',');

  const gestionRattachementCompte =
    configuration.tiers?.rattachement?.demandeRattachementCompteTiers &&
    $scope.teleserviceConfiguration?.workflow?.pageInformationsDemandeur?.gestionRattachementCompte;

  /**
   * Initialize the controller
   *
   * @returns {void}
   */
  function initialize() {
    currentDemandeur = StoreService.demandeur.get();
    const isDemandeurStatusMissing = currentDemandeur && !currentDemandeur.status;

    // create the demandeur if it does not already exist
    if (!currentDemandeur || isDemandeurStatusMissing) {
      const newDemandeur = new Tiers({
        status: 'TEMPORARY',
        linkedUsers: $scope.aide.linkedUsers,
        user: $scope.aide.user,
      }).getCleanEntity();

      // It should be updated again on creation success. But it needs to be up to date to permit retry if creation fails
      currentDemandeur = StoreService.demandeur.set(newDemandeur);

      const promise = tiersService
        .saveTiers(newDemandeur, $scope.mdm)
        .then((tiers) => {
          // at this moment we don't have a "famille", so... we don't need to add it to entity
          currentDemandeur = StoreService.demandeur.set(tiers);

          $scope.aide.demandeur = {
            href: currentDemandeur.id,
            title: currentDemandeur.title,
            expand: currentDemandeur,
          };

          return $scope.saveAide();
        })
        .catch((err) => {
          $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
          $log.error(`[depotSimpleDemandeurFamilleController] saveTiers`, err);
        });
      promises.push(promise);
    }

    // ! we don't need to wait for a created tiers to load the iframe. since tiers iframe just is used to verify siret and send data to parent (portail depot)
    // ! ref-tiers doesn't save data, only "portail depot" does
    $scope.referentielTiersIframeUrl = getVerificationSiretIframeUrl(
      restrictionFamillesTiers,
      gestionRattachementCompte,
      currentDemandeur
    );

    $scope.navigate.ns = $scope.familleDemandeurConfiguration.ns;
    $scope.viewConfiguration = $scope.familleDemandeurConfiguration;
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'tiers';
    $window.addEventListener('message', receiveMessageEvent);
    $scope.$on('$destroy', () => {
      $window.removeEventListener('message', receiveMessageEvent);
    });
  }

  $scope.navigate.next = () => {
    // Next step
    const isValid = $scope.tiersValidation?.valid;
    if (isValid) {
      const isKnownTiers = $scope.tiersValidation?.origin === 'referentiel';
      const nextStep = isKnownTiers ? 'demandeur-rattachement' : 'demandeur-identification';
      const adresseEtrangere = currentDemandeur.situations?.[0]?.etrangere;

      const rnaFilled = getDemandeMetadata().searchTypeDemandeur === 'RNA';

      const SIREN = ($scope.tiersFound ?? StoreService.demandeur.get())?.SIRET?.SIREN;
      const metadatas = {
        sirenReadOnly: !adresseEtrangere && !!SIREN,
        nicEditable: adresseEtrangere || !SIREN || rnaFilled,
        codeRnaReadOnly: rnaFilled,
      };

      $scope.depotForm.$error = {};

      $q.all(promises)
        .then(() => $scope.saveDemandeur({ refreshExternalData: true }))
        .then(() => $scope.goToStep(nextStep, undefined, undefined, undefined, metadatas));
    } else {
      getIframeElement().postMessage({ action: 'click-next' }, '*');
    }
  };

  // ! we wait for pending promises in depot workflow before displaying anything
  this.$onInit = () =>
    StoreService.depot.pendingPromises
      .promise()
      .then(() => initialize())
      .catch((err) => {
        $rootScope.$broadcast(
          'alerts',
          alertsService.getAlertError('connected.config.depot.errors.save'),
          'general-error-alert'
        );

        $log.error(`[depotSimpleDemandeurFamilleController] StoreService pendingPromises: `, err);
        $scope.loaded = true;
      });
}
