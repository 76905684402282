'use strict';
/**
 * @module aides
 * @name partageAide
 * @description
 *
 *   Partage d'une aide
 * @param {object} aide - aide model
 * @param {boolean} [disabled] - disable button if disabled is true
 * @param {boolean} [hasLabel] - show label on button if param is present
 * @example
 *
 *   <partage-aide aide="$ctrl.aide" hasLabel disabled="isDisabled"></partage-aide>
 */
class PartageAideController {
  constructor(partageAidesService, $uibModal, $scope, $attrs, $translate) {
    this.partageAidesService = partageAidesService;
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.$attrs = $attrs;
    this.$translate = $translate;
  }

  /**
   * Set isShared if there is at least one share
   *
   * @returns {boolean}
   */
  get isShared() {
    return this.sharings.length > 0;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.hasLabel = this.$attrs.hasOwnProperty('hasLabel') && this.$attrs.hasLabel !== 'false';
    this.sharings = [];
    if (this.aide.id) this.getSharings();
  }

  /**
   * Show the partage-aide modal
   *
   * @returns {void}
   */
  openModal() {
    const modalInstance = this.$uibModal.open({
      component: 'partageAideModal',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        sharings: () => this.sharings,
      },
    });

    modalInstance.result.then(
      (sharedEmails) => {
        this.saveSharings(sharedEmails);
      },
      () => {
        return true;
      }
    );
  }

  /**
   * Get shared emails list
   *
   * @returns {void}
   */
  getSharings() {
    this.isLoading = true;
    this.partageAidesService
      .getSharings(this.aide)
      .then((sharings) => {
        this.sharings = sharings;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Save sharedEmails list
   *
   * @param {string[]} sharedEmails
   * @returns {void}
   */
  saveSharings(sharedEmails) {
    this.isLoading = true;
    this.partageAidesService
      .saveSharings(this.aide, sharedEmails)
      .then((sharings) => {
        this.sharings = sharings;
        this.isLoading = false;
        this.$scope.$emit('aide-shared');
      })
      .catch(() => {
        this.getSharings();
      });
  }
}
PartageAideController.$inject = ['partageAidesService', '$uibModal', '$scope', '$attrs', '$translate'];

PartageAideController.$inject = ['partageAidesService', '$uibModal', '$scope', '$attrs', '$translate'];

angular.module('aides').component('partageAide', {
  templateUrl: 'aides/aides-components/partage-aide/partage-aide.component.html',
  bindings: {
    aide: '<',
    disabled: '<',
  },

  controller: PartageAideController,
});
