// jQuery
import './globals/jquery';
import 'bootstrap';

// Lodash
import './globals/lodash';

// Moment
import './globals/moment';

import './globals/bluebird';

// Angular
import 'angular';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-aria';
import 'angular-breadcrumb';
import 'bootstrap-filestyle';
import '@uirouter/angularjs';
import 'angular-translate';
import 'angular-dynamic-locale';

// Angular Strap
import 'angular-strap/dist/modules/compiler.js';
import 'angular-strap/dist/modules/dimensions.js';
import 'angular-strap/dist/modules/parse-options.js';
import 'angular-strap/dist/modules/tooltip.js';
import 'angular-strap/dist/modules/tooltip.tpl.js';
import 'angular-strap/dist/modules/aside.js';
import 'angular-strap/dist/modules/aside.tpl.js';
import 'angular-strap/dist/modules/select.js';
import 'angular-strap/dist/modules/select.tpl.js';
import 'angular-strap/dist/modules/popover.js';
import 'angular-strap/dist/modules/popover.tpl.js';
import 'angular-strap/dist/modules/modal.js';
import 'angular-strap/dist/modules/modal.tpl.js';

// Angular UI
import 'angular-ui-bootstrap/src/position/position.js';
import 'angular-ui-bootstrap/src/debounce/debounce.js';
import 'angular-ui-bootstrap/src/typeahead/typeahead.js';
import 'angular-ui-bootstrap/src/pagination/pagination.js';
import 'angular-ui-bootstrap/src/collapse/collapse.js';
import 'angular-ui-bootstrap/src/paging/paging.js';
import 'angular-ui-bootstrap/src/tabs/tabs.js';
import 'angular-ui-bootstrap/src/tabindex/tabindex.js';
import 'angular-ui-bootstrap/src/stackedMap/stackedMap.js';
import 'angular-ui-bootstrap/src/tooltip/tooltip.js';
import 'angular-ui-bootstrap/src/popover/popover.js';
import 'angular-ui-bootstrap/src/modal/modal.js';
import 'angular-ui-bootstrap/src/multiMap/multiMap.js';
import '@mgdis/ui-utils';

// ng Toast
import 'ng-toast';

// Kendo UI
import './globals/kendo';

//ng-focus-if directive
import 'ng-focus-if';

// Angular Loading Bar
import 'angular-loading-bar';

// Socket IO
import './globals/socket-io';

// Local storage
import 'angular-local-storage';

// File saver
import './globals/file-saver';

// Ng-pattern-restrict To Restrict user input based in regex
import 'ng-pattern-restrict';

//Compile angular html directives
import 'angular-bind-html-compile';

//Angular locale
import 'angular-i18n/angular-locale_fr-fr.js';

//Compile angular html directives
import 'ng-tags-input';

// autosize
import './globals/autosize';

// IBAN
import './globals/iban';

// UrlJoin
import './globals/url-join';

// chriso/validator.js
import './globals/validator';

// ngMap
import 'ngmap/build/scripts/ng-map.js';

// google reCAPTCHA
import 'angular-recaptcha';

// Bowser
import './globals/bowser';

// stringjs
import './globals/stringjs';

// iframe-resizer
import 'iframe-resizer/js/iframeResizer';

// fast-json-patch
import './globals/jsonpatch';

// ngMessages
import 'angular-messages';

// jsonpath-plus
import './globals/jsonpath-plus';

// Shortid
import './globals/shortid';

import 'handlebars';

// ngMatomo
import '@mgdis/ng-matomo';

// Angular file upload
import '@mgdis/angular-file-upload';

// ngConcurrenceInterceptor
import '@mgdis/client-http-concurrence/ng-interceptor';

//ngCookies
import 'angular-cookies';

/**
 * Disable some console error when value set at "log"
 * If we want to display the errors when running locally we must set value at "error"
 * Value at "error" must not be commited
 */
import { setMgAngularLogger } from '@mgdis/mg-components-helpers/angular';
setMgAngularLogger({ level: 'log' });

// mg-components
import { defineCustomElements } from '@mgdis/mg-components/loader';
defineCustomElements();

// notification-center
import { defineNotificationCenter } from './globals/notification-center';
defineNotificationCenter();
