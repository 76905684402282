'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('demandePaiementRecapitulatifOnlyController', [
  '$scope',
  '$log',
  '$stateParams',
  '$timeout',
  'informationsGeneralesConfiguration',
  'informationsComplementairesConfiguration',
  'planFinancementConfiguration',
  'domiciliationBancaireConfiguration',
  'piecesConfiguration',
  'recapitulatifConfiguration',
  'confirmationConfiguration',
  'teleserviceConfiguration',
  'demandePaiement',
  'aide',
  '$http',
  'recapitulatifService',
  'piecesService',
  'viewsService',
  'dataSchemasService',
  'demandesPaiementService',
  'indicateursService',
  'documentService',
  'tiers',
  function (
    $scope,
    $log,
    $stateParams,
    $timeout,
    informationsGeneralesConfiguration,
    informationsComplementairesConfiguration,
    planFinancementConfiguration,
    domiciliationBancaireConfiguration,
    piecesConfiguration,
    recapitulatifConfiguration,
    confirmationConfiguration,
    teleserviceConfiguration,
    demandePaiement,
    aide,
    $http,
    recapitulatifService,
    piecesService,
    viewsService,
    dataSchemasService,
    demandesPaiementService,
    indicateursService,
    documentService,
    tiers
  ) {
    'use strict';

    $scope.demandePaiement = demandePaiement;
    $scope.teleserviceConfiguration = teleserviceConfiguration;
    $scope.tiers = tiers;

    // The following configurations doesn't concern the pages options in teleservice.workflow
    $scope.informationsGeneralesConfiguration = informationsGeneralesConfiguration;
    $scope.informationsComplementairesConfiguration = informationsComplementairesConfiguration;
    $scope.planFinancementConfiguration = planFinancementConfiguration;
    $scope.domiciliationBancaireConfiguration = domiciliationBancaireConfiguration;
    $scope.piecesConfiguration = piecesConfiguration;
    $scope.recapitulatifConfiguration = recapitulatifConfiguration;
    $scope.confirmationConfiguration = confirmationConfiguration;

    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageRecapitulatif;
    $scope.viewConfiguration = $scope.recapitulatifConfiguration;

    $scope.urlFileIcons = './resources/images/file-icons/';
    $scope.demande = demandePaiement;

    if (_.get(aide, 'multiFinanceur', false)) {
      $scope.financeur = _.find(aide.multiFinancement.financeurs, ['href', $stateParams.financeur]);
    }

    $scope.demande.specifiques = $scope.demande.specifiques || [];
    // dataSchema delivers its data in an array "results", which mean we have to manually fix the expand
    if (
      !_.get($scope.demande.specifiques[0], 'schema.expand.reference') &&
      !_.get($scope.demande.specifiques[0], 'reference')
    ) {
      _.each($scope.demande.specifiques, function (field) {
        field.schema.expand = _.get(field.schema, 'expand.results.0');
      });
    }

    // translate fieldsName into path in demande
    var namesToPath = {
      montantDemande: 'montant.ttc',
      commentaire: 'commentaire',
      typePaiement: 'typePaiement.title',
      operationTerminee: 'operationTerminee',
      dateReelleFinOperation: 'dateReelleFinOperation',
      montantDepensesJustifiees: 'montantDepensesJustifiees.ttc',
    };

    var fieldsHidden = [];

    // If dispositif is present in typesPaiementAvecDepensesJustifiees, hide the field
    if (
      _.get($scope.teleserviceConfiguration, 'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees')
    ) {
      fieldsHidden['montantDepensesJustifiees'] = !_.includes(
        _.get(
          $scope.teleserviceConfiguration,
          'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees'
        ),

        _.get($scope.demande, 'typePaiement.title')
      );

      // Check if a value is set, else, set the field hidden
      if (!fieldsHidden['montantDepensesJustifiees'] && _.isEmpty(_.get($scope.demande, 'montantDepensesJustifiees'))) {
        fieldsHidden['montantDepensesJustifiees'] = true;
      }
    }

    // Complete a field's configuration with teleservice's configuration, its reference, and value
    var writeField = function (field, reference, path) {
      var fieldConfig =
        _.find($scope.teleserviceConfiguration.demandePaiement.fields, {
          reference: reference,
        }) || {};
      field.hidden = fieldsHidden[reference] || fieldConfig.hidden;
      field.reference = reference;
      field.value = _.get($scope.demande, path);
    };

    $scope.informationsGeneralesFields = _.transform(
      $scope.informationsGeneralesConfiguration.fields,
      function (fieldsList, field, reference) {
        if (namesToPath[reference]) {
          writeField(field, reference, namesToPath[reference]);
          fieldsList.push(field);
        }
      },
      []
    );

    // Check if all indicators are visible only for agent
    const allIndicatorsAreVisibleOnlyForAgent = _.every(
      _.get($scope, 'demande.demandeFinancement.expand.indicateursSaisis', []),
      (indicateurSaisi) => {
        return _.get(indicateurSaisi, 'definition.agentSeulement', false);
      }
    );

    // Check if the admin configuration asks to display the indicators and if there is at least one indicator to display
    // Check also if all indicators are not only visible for Agent
    $scope.displayIndicateursRealisationRecap =
      demandesPaiementService.mustDisplayIndicateursRealisation(_.get(aide, 'indicateursSaisis', [])) &&
      _.get($scope, 'teleserviceConfiguration.workflow.pageIndicateurs.actif') &&
      !allIndicatorsAreVisibleOnlyForAgent;

    $scope.iframeIndicateursRealisationPaiementRecap = null;
    // Build the url for "indicateurs" in mode "readOnly" and "realized"
    if ($scope.displayIndicateursRealisationRecap) {
      $scope.iframeIndicateursRealisationPaiementRecap = indicateursService.getIndicateursIframeUrl(
        { id: $scope.demandePaiement.demandeFinancement.href },
        true,
        'realized',
        true,
        null,
        $scope.demandePaiement.id
      );
    }

    // Needed to skip informations complementaires if all groups/fields are agentOnly
    $scope.displayInfosComplementaires = true;
    const infosCompHrefList = JSONPath(
      "$.[?(@.page == 'pageInformationsComplementaires')].schema.href",
      _.get($scope.demandePaiement, 'views')
    );

    dataSchemasService.getDataSchemasList(infosCompHrefList).then((infosComp) => {
      $scope.displayInfosComplementaires = demandesPaiementService.mustDisplayInformationsComplementaires(infosComp);
    });

    $scope.iframeInformationsComplementairesPaiementSrc = viewsService.getPageViewsIframeUrl({
      entity: $scope.demandePaiement,
      page: 'pageInformationsComplementaires',
      theme: 'demandeur',
      readOnly: true,
      hideExplanation: true,
      topTitleLevel: 5,
      contextHeaders: {
        'referentiel-tiers': [
          'Accept:application/vnd.mgdis.tiers-3.19.0+json',
          'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
        ],
      },
    });

    $scope.displayDocumentComptable = !!demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement);
    $scope.iframeDocumentComptablePaiementSrc = demandesPaiementService.getPlanFinancementIframeUrl(
      $scope.demandePaiement,
      true
    );

    // Pieces : we use a copy that will hide the pieces by default
    $scope.pieces = _.map($scope.demande.pieces, function (piece) {
      var pieceCopy = angular.copy(piece);
      pieceCopy.isVisible = piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
      pieceCopy.displayListDocuments = false;
      return pieceCopy;
    });

    // Document récapitulatif
    recapitulatifService
      .getRecapitulatifDemandePaiement($scope.demande, $scope.pieces)
      .then(function (documentRecapitulatif) {
        if (documentRecapitulatif) {
          var properties = _.get(documentRecapitulatif, 'object.properties');
          var expandedProperties = _.get(documentRecapitulatif, 'expand.properties');

          $scope.recapUrl =
            documentRecapitulatif.id ||
            _.get($scope.demande, '_links.mgs:documents.href') + '/' + _.get(properties, 'cmis:name.value');

          $scope.recapFileName = documentService.getDocumentName(documentRecapitulatif);

          $scope.recapMimeType = _.get(
            expandedProperties,
            'cmis:contentStreamMimeType.value',
            _.get(properties, 'cmis:contentStreamMimeType.value')
          );
        }
      });

    /**
     * Download the PDF recapitulatif document
     */
    $scope.downloadDocument = function () {
      $http
        .get($scope.recapUrl, {
          responseType: 'arraybuffer',
        })
        .then(
          function (reponse) {
            //var mimeType = pieceDocument.expand.properties['cmis:contentStreamMimeType'].value;
            var mimeType = $scope.recapMimeType || 'application/pdf';
            var blob = new Blob([reponse.data], {
              type: mimeType,
            });

            saveAs(blob, $scope.recapFileName || 'recapitulatif.pdf');
          },
          function (error) {
            $log.error('Unable to download recapitulatif: ', error);
          }
        );
    };

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeInformationsComplementairesPaiement, #iframeDocumentComptablePaiement'
      );
    }, 0);

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeIndicateursRealisationPaiementRecap'
      );
    }, 0);
  },
]);
