'use strict';
angular.module('portailDepotDemandeAide.depot', ['ui.router', 'ngMessages', 'aides']);

angular.module('portailDepotDemandeAide.depot').config([
  '$stateProvider',
  function ($stateProvider) {
    'use strict';

    var resolveViewConfiguration = function (module, view, namespace) {
      return [
        'viewManagerService',
        '$rootScope',
        function (viewManagerService, $rootScope) {
          return $rootScope.configurationPromise.then(() => {
            return viewManagerService.getViewConfiguration(module, view, 'teleservice.' + namespace);
          });
        },
      ];
    };

    const declarationCompteSignataire = [
      '$stateParams',
      'teleservicesService',
      'teleserviceConfiguration',
      ($stateParams, teleservicesService, teleserviceConfiguration) => {
        if (teleserviceConfiguration) return teleserviceConfiguration.declarationCompteSignataire;

        return teleservicesService
          .getTeleService($stateParams.configurationId, false)
          .then(({ declarationCompteSignataire }) => declarationCompteSignataire);
      },
    ];

    const tiersPhysiquesAlreadyLinked = [
      '$q',
      'tiersService',
      'mdm',
      'tiers',
      ($q, tiersService, mdm, tiers) => {
        return tiersService
          .getAllCurrentUserTiers()
          .then((allTiers) => {
            return allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE');
          })
          .then((tiersPhysiques) => {
            return Promise.all(
              tiersPhysiques.map((tiersPhysique) => {
                //! don't fetch tiers twice
                if (tiers && tiers.id === tiersPhysique.id) return $q.resolve(tiers);
                return tiersService.getTiersById(tiersPhysique.id, mdm);
              })
            );
          });
      },
    ];

    const contributionModificationState = {
      // Procedure model 'simple', routing between pages inside the procedure is done using includes
      // in order to prevent bugs in the workflow caused by browser navigation actions
      url: '/contributionModification?p&c',
      controller: 'depotSimpleController',
      templateUrl: 'depot/simple/depot-simple.html',
      reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
      resolve: {
        // Contribution
        contribution: [
          '$stateParams',
          '$state',
          '$translate',
          'contributionsService',
          'contributionsFactory',
          'contributionsConstants',
          ($stateParams, $state, $translate, contributionsService, contributionsFactory, contributionsConstants) => {
            return contributionsService
              .get($stateParams.c)
              .then((contribution) => {
                if (contribution && contribution.statut === contributionsConstants.status.ANSWERED) {
                  contributionsService.notifications.alreadyDone();
                  return $state.go('app.connected.dashboard.accueil', { tenantId: $stateParams.tenantId });
                }

                return contributionsFactory.setContribution(contribution);
              })
              .catch((err) => {
                let message = '';
                switch (err.status) {
                  case 403:
                    message = 'connected.depot.errors.restrictedDemande';
                    break;
                  default:
                    message = 'connected.depot.errors.unknown';
                }
                NotificationCenter.postMessage({
                  content: $translate.instant(message),
                  variant: 'danger',
                });

                throw err;
              });
          },
        ],

        aide: [
          '$stateParams',
          'mdm',
          'aidesService',
          ($stateParams, mdm, aidesService) => {
            const aideReference = $stateParams.p;
            const contributionReference = $stateParams.c;
            const expands = [
              'teleservice',
              'teleservice.expand.thematiques',
              'demandeur.expand.famille',
              'beneficiaires.expand.famille',
              'pieces.documents',
              'specifiques.value',
              'planFinancement.depense.postes.lignes.pieces.documents',
              'domiciliationBancaire.pieces.documents',
              'bourse',
            ].join(',');
            return aidesService.getAideWithContribution(aideReference, contributionReference, mdm, expands);
          },
        ],
        teleserviceWithExtension: [
          'teleservicesService',
          'aide',
          (teleservicesService, aide) => {
            if (aide.teleserviceExtension) {
              const teleserviceReferenceWithRevision = aide.teleservice.href.split('/').pop();
              return teleservicesService
                .getTeleService(teleserviceReferenceWithRevision, null, {
                  teleserviceExtension: aide.teleserviceExtension.href,
                })
                .then((teleserviceWithExtension) => {
                  // remove the "workflow.simple" key to match the teleserviceConfiguration
                  teleserviceWithExtension.workflow =
                    teleserviceWithExtension.workflow[aide.teleservice.expand.workflow.type];
                  teleserviceWithExtension.workflow.type = aide.teleservice.expand.workflow.type;
                  return teleserviceWithExtension;
                });
            }

            return null;
          },
        ],
        teleserviceConfigurationWithRevision: [
          '$stateParams',
          '$translate',
          '$location',
          'teleservicesService',
          'configuration',
          'aide',
          function ($stateParams, $translate, $location, teleservicesService, configuration, aide) {
            // If we have an id persistence, we retrieve the teleservice same if it's disable
            var persistence = $location.search().p ? true : false;

            const teleserviceRevisionWithRevision = aide.teleservice.href.split('/').pop();
            return teleservicesService
              .getTeleService(teleserviceRevisionWithRevision, persistence, { expand: 'thematiques' })
              .then(function (teleservice) {
                // Modify by reference i18n's configuration and force the refresh.
                // Necessary to fetch files with dynamic names based on current teleservice
                configuration.i18n.teleservice.id = $stateParams.configurationId.split('?')[0];

                if (!teleservice) {
                  return $translate.refresh().then(function () {
                    return {};
                  });
                }

                if (teleservice.workflow.type) {
                  configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                  return $translate.refresh().then(function () {
                    teleservice.workflow = teleservice.workflow[configuration.i18n.teleservice.workflow];
                    // copy type to control access in controller
                    teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                    return teleservice;
                  });
                } else {
                  configuration.i18n.teleservice.workflow = teleservice.typeWorkflow;
                  return teleservice;
                }
              });
          },
        ],
        demandeur: [
          '$translate',
          'aide',
          'ngToast',
          ($translate, aide, ngToast) => {
            const demandeur = aide.demandeur?.expand;
            if (demandeur && (!demandeur.id || !demandeur.title)) {
              ngToast.create({
                content: $translate.instant('connected.depot.errors.contributionAccessError'),
                className: 'danger',
                timeout: 5000,
                maxNumber: 1,
              });
              throw new Error('Expanded demandeur has no id or title');
            }
            return demandeur;
          },
        ],

        // Simple
        simpleConfiguration: resolveViewConfiguration('simple'),

        // Public Settings Financement
        publicSettingsFinancement: [
          'aidesService',
          (aidesService) => {
            return aidesService.getPublicSettingsFinancement();
          },
        ],

        declarationCompteSignataire,

        attestationDeclarationHonneur: [
          'configuration',
          '$http',
          (configuration, $http) => {
            return $http
              .get(configuration.referentielPiece.url + '/attestationDeclarationHonneur')
              .then(({ data }) => data);
          },
        ],

        tiersPhysiquesAlreadyLinked,

        // Only used for shared aides in depotSimpleController
        isAccessedThroughSharing: () => null,
        lockingSocket: () => null,
        isDemandeurReadOnly: () => null,
        tiersUsedForDepot: () => null,
        teleserviceOpeningData: () => null,
      },
    };

    const contributionRedirectionState = _.assign({}, contributionModificationState);
    contributionRedirectionState.url = '/contributionRedirection?p&c';

    $stateProvider
      .state('app.connected.config', {
        // This is a abstract state to share some logic between all states that depend on a configuration (=téléprocédure)
        url: '/:configurationId',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          configurationId: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.configurationId;
            },
          ],

          tiers: [
            'tiersService',
            'mdm',
            'selectedTiers',
            function (tiersService, mdm, selectedTiers) {
              return selectedTiers ?? tiersService.getCurrentTiers(mdm, 'famille');
            },
          ],
        },
      })
      .state('app.connected.config.depot', {
        // Shared logic accross all pages of any workflow, mostly a rich template
        url: '/depot',
        controller: 'depotController',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          teleserviceConfiguration: [
            '$stateParams',
            '$translate',
            '$location',
            'teleservicesService',
            'configuration',
            function ($stateParams, $translate, $location, teleservicesService, configuration) {
              // If we have an id persistence, we retrieve the teleservice same if it's disable
              var persistence = $location.search().p ? true : false;

              return teleservicesService
                .getTeleService($stateParams.configurationId, persistence, { expand: 'thematiques' })
                .then(function (teleservice) {
                  // Modify by reference i18n's configuration and force the refresh.
                  // Necessary to fetch files with dynamic names based on current teleservice
                  configuration.i18n.teleservice.id = $stateParams.configurationId.split('?')[0];

                  if (!teleservice) {
                    return $translate.refresh().then(function () {
                      return {};
                    });
                  }

                  if (teleservice.workflow.type) {
                    configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                    return $translate.refresh().then(function () {
                      teleservice.workflow = teleservice.workflow[configuration.i18n.teleservice.workflow];
                      // copy type to control access in controller
                      teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                      return teleservice;
                    });
                  } else {
                    configuration.i18n.teleservice.workflow = teleservice.typeWorkflow;
                    return teleservice;
                  }
                });
            },
          ],
          publicSettingsTiers: [
            'tiersService',
            'adresseService',
            function (tiersService, adresseService) {
              return tiersService.getPublicSettingsTiers().then(function (publicSettingsTiers) {
                // Configure api ban
                adresseService.setApiBan(
                  _.get(publicSettingsTiers, 'remoteServices.apiban.active', false),
                  _.get(publicSettingsTiers, 'remoteServices.apiban.url')
                );

                return publicSettingsTiers;
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.simple', {
        // Procedure model 'simple', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/simple?p&fromEchange&user&theme',
        controller: 'depotSimpleController',
        templateUrl: 'depot/simple/depot-simple.html',
        reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
        resolve: {
          aide: [
            '$state',
            '$stateParams',
            '$translate',
            'ngToast',
            'configuration',
            'aidesService',
            'userSessionService',
            'teleserviceConfiguration',
            'teleservicesService',
            'Aide',
            'mdm',
            (
              $state,
              $stateParams,
              $translate,
              ngToast,
              configuration,
              aidesService,
              userSessionService,
              teleserviceConfiguration,
              teleservicesService,
              Aide,
              mdm
            ) => {
              var teleserviceUrl = teleserviceConfiguration.id;
              var user = userSessionService.getUser();
              if ($stateParams.p) {
                var aideReference = $stateParams.p;
                let expands = [
                  'teleservice',
                  'teleservice.expand.thematiques',
                  'demandeur.expand.famille',
                  'beneficiaires.expand.famille',
                  'pieces.documents',
                  'specifiques.value',
                  'planFinancement.depense.postes.lignes.pieces.documents',
                  'domiciliationBancaire.pieces.documents',
                  'bourse',
                ];
                expands = expands.join(',');
                return aidesService
                  .get(aideReference, mdm, expands)
                  .then(function (aide) {
                    if (!_.includes(['REQUESTED', 'WAITING_FOR_CERTIFICAT', 'REGISTERED'], aide.status)) {
                      $state.go('app.connected.dashboard.accueil');
                      return;
                    }

                    if (aide.teleserviceExtension) {
                      return teleservicesService
                        .getTeleService(teleserviceConfiguration.reference, null, {
                          teleserviceExtension: aide.teleserviceExtension.href,
                        })
                        .then(function (teleserviceWithExtension) {
                          // remove the "workflow.simple" key to match the teleserviceConfiguration
                          teleserviceWithExtension.workflow =
                            teleserviceWithExtension.workflow[teleserviceConfiguration.workflow.type];
                          teleserviceWithExtension.workflow.type = teleserviceConfiguration.workflow.type;
                          _.assign(teleserviceConfiguration, teleserviceWithExtension);
                          return aide;
                        });
                    } else {
                      return aide;
                    }
                  })
                  .catch((err) => {
                    let message = '';
                    switch (err.status) {
                      case 410:
                        message = 'connected.depot.errors.demandeDeleted';
                        break;
                      default:
                        message = 'connected.depot.errors.unknown';
                    }

                    ngToast.create({
                      content: $translate.instant(message),
                      className: 'danger',
                      timeout: 5000,
                      maxNumber: 1,
                    });

                    const isCurrentRouteSharedAides = $state.current.name.includes('sharedAides');
                    $state.go(
                      'app.connected.dashboard.aides.demandesAides.' +
                        (isCurrentRouteSharedAides ? 'sharedAides' : 'aides'),
                      $stateParams
                    );
                  });
              } else {
                return new Aide({
                  status: 'REQUESTED',
                  dispositif: teleserviceConfiguration.dispositif,
                  user: {
                    title: user.displayName,
                    rel: 'user',
                    href: configuration.user.accountManagement + /users/ + user.userName,
                  },

                  teleservice: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    rel: 'teleservice',
                    expand: teleserviceConfiguration,
                  },

                  linkedUsers: [
                    {
                      title: user.displayName,
                      rel: 'user',
                      form: 'ADMINISTRATOR',
                      href: configuration.user.accountManagement + /users/ + user.userName,
                    },
                  ],
                });
              }
            },
          ],
          teleserviceConfigurationWithRevision: () => null,
          teleserviceWithExtension: () => null,

          isAccessedThroughSharing: [
            'aidesService',
            'aide',
            (aidesService, aide) => {
              return aidesService.isAccessedThroughSharing(aide);
            },
          ],

          demandeur: [
            '$q',
            'aide',
            'tiers',
            'Tiers',
            'isAccessedThroughSharing',
            'cmisService',
            'userSessionService',
            ($q, aide, tiers, Tiers, isAccessedThroughSharing, cmisService, userSessionService) => {
              let demandeur;
              let promises = [];
              if (tiers && !isAccessedThroughSharing) {
                /**
                 * if we are attached to a tiers, then it become the demandeur of the demande
                 * it is the case when we create a new demande OR edit a REQUESTED demande (that may have had a different demandeur)
                 * if we are in sharing mode, we don't want to do that
                 */
                demandeur = new Tiers(tiers);
              } else if (aide.demandeur.href && aide.demandeur.expand) {
                demandeur = aide.demandeur.expand;
                // In this case we don't have cmis properties in documents so we need to retrieve them
                // Only if tiers is linked to the user's demandeur
                const user = userSessionService.getUser();
                const linkedUsersHref = demandeur.linkedUsers.map(({ href }) => href);

                if (linkedUsersHref.includes(user.self)) {
                  demandeur.domiciliationsBancaires?.forEach((domiciliationBancaire) => {
                    domiciliationBancaire?.pieces?.forEach((piece) => {
                      piece?.documents?.forEach((document) => {
                        const promise = cmisService.expandDocument(document);
                        promises.push(promise);
                      });
                    });
                  });
                }
              }
              return $q.all(promises).then(() => demandeur);
            },
          ],

          lockingSocket: [
            '$state',
            '$translate',
            'lockAidesService',
            'ngToast',
            'aide',
            ($state, $translate, lockAidesService, ngToast, aide) => {
              return lockAidesService.getLockingSocket(aide).catch((err) => {
                $state.go('app.connected.dashboard.recapitulatif', { aide: aide.reference });
                ngToast.create({
                  content: $translate.instant('connected.depot.errors.demandeLockedByAnotheruser', err.data),
                  className: 'danger',
                  timeout: 5000,
                  maxNumber: 1,
                });
              });
            },
          ],

          isDemandeurReadOnly: [
            'tiersService',
            'demandeur',
            'isAccessedThroughSharing',
            (tiersService, demandeur, isAccessedThroughSharing) => {
              return isAccessedThroughSharing && demandeur
                ? tiersService.canModifyTiers(demandeur.id).then((result) => !result)
                : false;
            },
          ],

          tiersUsedForDepot: [
            'aide',
            'tiers',
            'isAccessedThroughSharing',
            (aide, tiers, isAccessedThroughSharing) => {
              if (isAccessedThroughSharing) {
                /**
                 * If sharing mode and demandeur is not validated,
                 * act as no tiers to display the tiers form
                 */
                return _.get(aide.demandeur, 'expand.status') === 'TEMPORARY' ? null : aide.demandeur.expand;
              }
              return tiers;
            },
          ],

          // Simple
          simpleConfiguration: resolveViewConfiguration('simple'),

          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            (aidesService) => {
              return aidesService.getPublicSettingsFinancement();
            },
          ],

          contribution: [
            'contributionsFactory',
            (contributionsFactory) => {
              return contributionsFactory.setContribution(null);
            },
          ],

          declarationCompteSignataire,

          attestationDeclarationHonneur: [
            'configuration',
            '$http',
            (configuration, $http) => {
              return $http
                .get(configuration.referentielPiece.url + '/attestationDeclarationHonneur')
                .then(function (res) {
                  return res.data;
                });
            },
          ],

          echangesActif: [
            'aide',
            'teleservicesService',
            'isAccessedThroughSharing',
            (aide, teleservicesService, isAccessedThroughSharing) => {
              // PLAID-66072, RG 2-08: no access to echanges in sharing mode
              if (isAccessedThroughSharing) return false;

              return teleservicesService.getTeleServiceFromObject(aide, true).then(function (teleservice) {
                return teleservice.echangesActif;
              });
            },
          ],
          tiersPhysiquesAlreadyLinked,
          teleserviceOpeningData: [
            '$q',
            'aidesService',
            'aide',
            ($q, aidesService, aide) => {
              const promise = aide.id ? aidesService.isOpen(aide.id) : $q.resolve(aide.teleservice.expand._meta);
              return promise;
            },
          ],
          publicSettingsTiers: ['publicSettingsTiers', (publicSettingsTiers) => publicSettingsTiers],
        },

        onEnter: [
          '$rootScope',
          'aide',
          'echangesActif',
          function ($rootScope, aide, echangesActif) {
            $rootScope.echangesActif = echangesActif;
            if (aide.reference) {
              $rootScope.displayEchangeMenu = {
                value: echangesActif,
                demandeId: aide.reference,
              };
            }
          },
        ],
        onExit: [
          '$rootScope',
          function ($rootScope) {
            $rootScope.displayEchangeMenu = {
              value: false,
            };
          },
        ],
      })
      .state('app.connected.config.depot.demandePaiement', {
        // Procedure model 'demandePaiement', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/:referenceAide/demandePaiement?reference&financeurHref&dossier',
        controller: 'demandePaiementController',
        templateUrl: 'depot/demande-paiement/demande-paiement.html',
        dynamic: true, // ?reference will be set after some time, do not reload the state for it
        params: {
          aide: null,
        },

        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              return aidesService.getForPaiements($stateParams.referenceAide);
            },
          ],

          demandePaiement: [
            '$stateParams',
            'demandesPaiementService',
            'aide',
            'teleserviceConfiguration',
            'StoreService',
            function ($stateParams, demandesPaiementService, aide, teleserviceConfiguration, StoreService) {
              var teleserviceUrl = teleserviceConfiguration.id;

              if ($stateParams.reference) {
                const promise = demandesPaiementService
                  .getDemandePaiement($stateParams.reference, {
                    params: {
                      $expand: 'pieces.documents,domiciliationBancaire.pieces.documents,attributaire',
                    },
                  })
                  .then(function (demandePaiement) {
                    demandePaiement.teleservicePaiement = {
                      id: teleserviceUrl,
                      href: teleserviceUrl,
                      title: teleserviceConfiguration.title,
                      expand: teleserviceConfiguration,
                    };

                    demandePaiement.demandeFinancement = {
                      href: aide.id,
                      expand: aide,
                    };

                    return demandePaiement;
                  });
                StoreService.demandePaiement.pendingPromises.push(promise);
                return promise;
              } else {
                return {
                  statut: 'EN_COURS',
                  teleservicePaiement: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    expand: teleserviceConfiguration,
                  },
                  demandeFinancement: {
                    href: aide.id,
                    expand: aide,
                  },
                };
              }
            },
          ],

          typesPaiement: [
            'paiementService',
            'demandePaiement',
            function (paiementService, demandePaiement) {
              if (_.get(demandePaiement, 'teleservicePaiement.expand')) {
                return demandePaiement.teleservicePaiement.expand.workflow.pageInformationsGenerales.typesPaiement;
              }
              return paiementService.getTypesPaiement(demandePaiement.teleservicePaiement.id);
            },
          ],

          informationsComplementaires: [
            'teleserviceConfiguration',
            'dataSchemasService',
            function (teleserviceConfiguration, dataSchemasService) {
              // Get list of informations complementaires data-schemas from teleservice paiement
              const infosCompHrefList = JSONPath(
                "$.[?(@.page == 'pageInformationsComplementaires')].schema.href",
                _.get(teleserviceConfiguration, 'views')
              );

              const infosComplementaires = dataSchemasService
                .getDataSchemasList(infosCompHrefList)
                .then((infosComp) => {
                  return infosComp;
                });
              return infosComplementaires;
            },
          ],

          decisions: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              return aidesService.getDecisionsHistory($stateParams.referenceAide, $stateParams.dossier);
            },
          ],

          pagesToDisplay: [
            '$q',
            'aide',
            'demandesPaiementService',
            'informationsComplementaires',
            'indicateursService',
            'teleserviceConfiguration',
            function (
              $q,
              aide,
              demandesPaiementService,
              informationsComplementaires,
              indicateursService,
              teleserviceConfiguration
            ) {
              // True if the "indicateur" page is active on the "demande paiement" workflow
              const pageIndicateurIsActif = teleserviceConfiguration?.workflow?.pageIndicateurs?.actif;

              // True if the "informations-complementaires" page is active on the "demande paiement" workflow
              const pageInformationsComplementairesIsActif =
                teleserviceConfiguration?.workflow?.pageInformationsComplementaires?.actif;

              return $q
                .all([
                  indicateursService.showIndicateur(aide),
                  demandesPaiementService.mustDisplayInformationsComplementaires(informationsComplementaires),
                ])
                .then(([displayIndicateurs, displayInformationsComplementaires]) => {
                  return {
                    indicateurs: displayIndicateurs && pageIndicateurIsActif,
                    informationsComplementaires:
                      displayInformationsComplementaires && pageInformationsComplementairesIsActif,
                  };
                });
            },
          ],
        },
      })
      .state('app.connected.config.depot.offresStage', {
        // Procedure model 'offresStage', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/offresStage?ref',
        controller: 'depotOffresStageController',
        templateUrl: 'depot/offres-stage/depot-offres-stage.html',
        reloadOnSearch: false, // ?ref will be set after some time, do not reload the state for it
        resolve: {
          isStagesLicenceActive: [
            'sessionServiceAPI',
            'jwtSessionService',
            function (sessionServiceAPI, jwtSessionService) {
              sessionServiceAPI.setJwtKey(jwtSessionService.getJwtKey());
              return sessionServiceAPI.isLicenseActive('depot-offres-stage');
            },
          ],
          offreStage: [
            '$stateParams',
            'configuration',
            'offresStageService',
            'userSessionService',
            'teleserviceConfiguration',
            'OffreStage',
            function (
              $stateParams,
              configuration,
              offresStageService,
              userSessionService,
              teleserviceConfiguration,
              OffreStage
            ) {
              var teleserviceUrl = teleserviceConfiguration.id;
              var user = userSessionService.getUser();

              if ($stateParams.ref) {
                var referenceOffreStage = $stateParams.ref;
                var expands = [
                  'teleservice',
                  'teleservice.expand.thematiques',
                  'demandeur.expand.famille',
                  'beneficiaires.expand.famille',
                  'pieces.documents',
                  'domiciliationBancaire.pieces.documents',
                ].join(',');
                return offresStageService.get(referenceOffreStage, expands);
              } else {
                //initialisation des pieces
                var pieces = _.clone(_.get(teleserviceConfiguration, 'workflow.pagePieces.modelesPieces'));
                _.forEach(pieces, function (piece) {
                  delete piece.pieceDeReference;
                });
                return new OffreStage({
                  statut: 'REQUESTED',
                  dispositif: teleserviceConfiguration.dispositif,
                  user: {
                    title: user.displayName,
                    rel: 'user',
                    href: configuration.user.accountManagement + /users/ + user.userName,
                  },

                  adresse: {},
                  teleservice: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    rel: 'teleservice',
                    expand: teleserviceConfiguration,
                  },

                  pieces: pieces,
                  history: {
                    begin: {},
                    events: [],
                    end: {},
                  },
                });
              }
            },
          ],

          cadresDepot: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initCadresDepot();
            },
          ],

          secteursActivite: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initSecteursActiviteStructure();
            },
          ],

          effectifs: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initEffectifStructure();
            },
          ],

          dureesStage: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initDureesStage();
            },
          ],

          typesStage: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initTypesStage();
            },
          ],

          qualifs: [
            'offresStageService',
            'settings',
            function (offresStageService) {
              return offresStageService.initNiveauxQualificationRequis();
            },
          ],

          siret: [
            'tiersService',
            'userSessionService',
            'mdm',
            function (tiersService, mdm) {
              return tiersService.getCurrentTiers(mdm).then(function (tiers) {
                if (!_.isEmpty(tiers)) {
                  return tiers.SIRET;
                }
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributions', {
        url: '/contributions/:contributionRef',
        component: 'contributionAvisComponent',
        resolve: {
          // Contribution
          contribution: [
            '$stateParams',
            'contributionsService',
            function ($stateParams, contributionsService) {
              return contributionsService.get($stateParams.contributionRef);
            },
          ],

          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function (aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],

          demande: [
            'contribution',
            function (contribution) {
              return _.get(contribution, 'demande', null);
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributionRedirection', contributionRedirectionState)
      .state('app.connected.config.depot.contributionModification', contributionModificationState);
  },
]);
