'use strict';
angular.module('portailDepotDemandeAide.rattachement', ['ui.router']);

angular.module('portailDepotDemandeAide.rattachement').config([
  '$stateProvider',
  function ($stateProvider) {
    'use strict';

    $stateProvider.state('app.connected.rattachement', {
      // Display the list of aides of the user
      url: '/rattachement/:rattachementId',
      controller: 'rattachementController',
      templateUrl: 'rattachement/rattachement.html',
      resolve: {
        rattachementId: [
          '$stateParams',
          ($stateParams) => {
            return $stateParams.rattachementId;
          },
        ],

        tiers: [
          'tiersService',
          'mdm',
          (tiersService, mdm) => {
            return tiersService.getCurrentTiers(mdm, 'linkedUsers.*');
          },
        ],
      },
    });
  },
]);
