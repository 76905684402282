'use strict';
angular.module('tiers').directive('identificationAssociationForm', [
  'tiersValidationService',
  'validationService',
  'tiersService',
  'StoreService',
  '$timeout',
  function (tiersValidationService, validationService, tiersService, StoreService, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl:
        'tiers/tiers-directives/identification-form/includes/identification-association-form/identification-association-form.html',
      require: '^form',
      scope: {
        tiers: '=',
        mdm: '=',
        edit: '=',
        controleSiretActif: '<',
        viewConfiguration: '=',
        forbiddenSiret: '=',
        filteredFormesJuridiques: '=',
        displayLegend: '<',
      },

      link: function (scope, element, attributes, formController) {
        //Get custom label if tiers sans SIRET

        tiersService.getReferentielTiersI18n().then((result) => {
          scope.viewConfiguration.fields.noSiretReason.label = result.tiers.sansSiret.motifAbsence;
          scope.viewConfiguration.fields.noSiret.label = result.tiers.sansSiret.libelle;
        });

        // If next/previous, display existing tiers in vignette
        scope.existingTiers = StoreService.existingTiers.get();
        // If SIRET does not correspond to existing tiers in store, clean existing tiers
        if (
          scope.existingTiers &&
          scope.existingTiers.SIRET &&
          scope.tiers.SIRET &&
          scope.existingTiers.SIRET.SIREN !== scope.tiers.SIRET.SIREN &&
          scope.existingTiers.SIRET.NIC !== scope.tiers.SIRET.NIC
        ) {
          StoreService.existingTiers.clean();
          delete scope.existingTiers;
        }
        //Field noSiretReason is read only by default
        scope.noSiretReasonReadOnly = true;
        scope.tiersSansSiret = scope.tiers.famille.expand.tiersSansSiret;

        /**
         *
         * @param {object} publicSettingsTiers
         * @param {boolean} etrangere
         */
        function updateNafRequired(publicSettingsTiers, etrangere) {
          scope.nafRequired = _.get(publicSettingsTiers, 'naf.required', false) && !etrangere;
          if (scope.nafRequired) {
            scope.viewConfiguration.fields.NAFNiv732.required = true;
            scope.viewConfiguration.fields.NAFNiv732.hidden = false;
          } else {
            scope.viewConfiguration.fields.NAFNiv732.required = false;
            if (_.isNil(scope.viewConfiguration.fields.NAFNiv732.hidden)) {
              scope.viewConfiguration.fields.NAFNiv732.hidden = false;
            }
          }
        }

        // Récupération des publicSetting du tiers pour la gestion du NAF et Code RNA
        // Si un des deux champs est presents, on fait l'appel au referentiel-tiers
        // PLAID-18108 pour tableau des cas possibles
        if (_.has(scope.viewConfiguration, 'fields.codeRNA') || _.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
          const publicSettingsTiers = tiersService.getCachedPublicSettings();

          scope.codeRNARequired = publicSettingsTiers?.codeRNA?.required ?? false;

          // RNA code handling
          if (_.has(scope.viewConfiguration, 'fields.codeRNA')) {
            if (scope.codeRNARequired) {
              scope.viewConfiguration.fields.codeRNA.required = true;
              scope.viewConfiguration.fields.codeRNA.hidden = false;
            } else {
              scope.viewConfiguration.fields.codeRNA.required = false;
              if (_.isNil(scope.viewConfiguration.fields.codeRNA.hidden)) {
                scope.viewConfiguration.fields.codeRNA.hidden = false;
              }
            }
          }

          // NAF handling
          if (_.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
            scope.situationPrincipale = _.find(scope.tiers?.situations, { principale: true });
            const etrangere = scope.situationPrincipale?.etrangere;
            updateNafRequired(publicSettingsTiers, etrangere);
            scope.$watch('situationPrincipale.etrangere', (etrangere) => {
              updateNafRequired(publicSettingsTiers, etrangere);
            });
          }
        }

        if (!scope.edit) {
          if (!_.get(scope.tiers, 'thematiquesLiees.association')) {
            _.set(scope.tiers, 'thematiquesLiees.association', {});
          }
          scope.tiers.thematiquesLiees.association.inscriptionRegistre =
            scope.tiers.thematiquesLiees.association.inscriptionRegistre || {};
        }

        /**
         * Function called when SIRET looses focus
         * Allows to modify fields status
         *
         * @param {string} fieldName
         */
        scope.blurFieldsSiret = function (fieldName) {
          if (scope.form[fieldName].$invalid || scope.form[fieldName].$dirty) {
            scope.fields[fieldName].status = 'error';
          } else {
            scope.fields[fieldName].status = 'success';
          }
        };

        // init sansSiret
        if (scope.tiersSansSiret) {
          if (!scope.tiers.sansSIRET) {
            _.set(scope.tiers, 'sansSIRET.valeur', false);
          }
          if (!scope.tiers.SIRET || !scope.tiers.SIRET.SIREN) {
            scope.shouldDisplaySansSiretFields = true;
          }
          setNoSiret();
        }
        /**
         * Set requirements for SIRET and sansSIRET.raison
         * Erase existingTiers
         */
        function setNoSiret() {
          const etrangere = scope.situationPrincipale && scope.situationPrincipale.etrangere;

          if (scope.tiers.sansSIRET.valeur) {
            scope.noSiretReasonReadOnly = false;
            delete scope.existingTiers;
            scope.tiers.SIRET = {};
          }
          if (!scope.tiers.sansSIRET.valeur) {
            scope.tiers.sansSIRET.raison = undefined;
            scope.noSiretReasonReadOnly = true;
          }
          scope.viewConfiguration.fields.noSiretReason.required = !etrangere && scope.tiers.sansSIRET.valeur;

          scope.viewConfiguration.fields.SIREN.required = !etrangere && !scope.tiers.sansSIRET.valeur;

          scope.viewConfiguration.fields.NIC.required = !etrangere && !scope.tiers.sansSIRET.valeur;
        }
        scope.setNoSiret = function () {
          setNoSiret();
        };

        /**
         * Allow to control formeJuridique value and delete if it's not valued
         */
        scope.onFormeJuridiqueChange = function () {
          if (_.isEmpty(scope.tiers.formeJuridique)) {
            delete scope.tiers.formeJuridique;
          }
        };

        // Method that verify SIRET
        scope.checkSiretValidity = tiersValidationService.checkSiretValidity;

        // If SIRET or NIC can be edited, check for tiers with same SIRET and display vignette
        scope.searchExistingTiers = function (siren, nic) {
          if (siren && nic && scope.controleSiretActif && scope.checkSiretValidity(siren, nic)) {
            tiersService.controlerUniciteSIRET(siren, nic).then((existingTiers) => {
              if (existingTiers.length > 0) {
                // We save the existingTiers in case of next/previous
                StoreService.existingTiers.set(existingTiers[0]);
                scope.existingTiers = existingTiers[0];
              } else {
                StoreService.existingTiers.clean();
                delete scope.existingTiers;
              }
            });
          } else {
            delete scope.existingTiers;
          }
        };

        /**
         * manage copy paste in siret (siren) field
         *
         * @param {object} event
         */
        scope.pasteSiret = function (event) {
          const pastedData = event.originalEvent.clipboardData.getData('text');
          const cleanedData = pastedData.replace(/\s/g, '');
          const toSIREN = cleanedData.substr(0, 9);
          const toNIC = cleanedData.substr(9, 5) || '';
          _.set(scope.tiers, 'SIRET.SIREN', toSIREN);
          _.set(scope.tiers, 'SIRET.NIC', toNIC);
          scope.form['SIREN'].$setDirty(true);
          scope.form['NIC'].$setDirty(true);
          $timeout(() => {
            scope.blurFieldsSiret('SIREN');
            scope.blurFieldsSiret('NIC');
            scope.searchExistingTiers(toSIREN, toNIC);
          });
        };

        // Check if email respect RFCs
        scope.validationService = validationService;

        // Form instance
        scope.form = formController;

        // Management of the SIREN and NIC fields
        scope.fields = {
          SIREN: {
            status: undefined,
          },

          NIC: {
            status: undefined,
          },
        };

        // We use timeout for update 'scope.fields' data
        // If user press 'next' and the siret is not valid
        // The error will be displayed
        $timeout(function () {
          const siret = scope.tiers.SIRET;
          const isThereForbiddenSiret = scope.forbiddenSiret && scope.forbiddenSiret.SIREN && scope.forbiddenSiret.NIC;
          const hasForbiddenSiret =
            isThereForbiddenSiret &&
            scope.forbiddenSiret.SIREN === siret.SIREN &&
            scope.forbiddenSiret.NIC === siret.NIC;

          // the siret is composed of siren and nic
          const fields = ['NIC', 'SIREN'];
          fields.forEach((field) => {
            if (scope.form[field] && (scope.form[field].$invalid || (field === 'NIC' && hasForbiddenSiret))) {
              scope.fields[field].status = 'error';
            } else {
              scope.fields[field].status = 'success';
            }
          });
        });

        tiersService.getFamilleAssociationIdentification(scope.tiers).then((associationIdentification) => {
          scope.displayRegistreInscription = _.get(
            associationIdentification,
            'inscriptionRegistreTribunalInstance.visible'
          );
        });
        scope.logoVisible = _.get(scope.tiers, 'famille.expand.logo.visible');
        scope.logoOptions = {
          allowedExtensions: _.get(scope.tiers, 'famille.expand.logo.allowedExtensions'),
          maxDocumentSize: _.get(scope.tiers, 'famille.expand.logo.maxDocumentSize'),
          maxWidth: 300,
          maxHeight: 300,
        };
      },
    };
  },
]);
