'use strict';

class DashboardController {
  constructor(
    $rootScope,
    $scope,
    $modal,
    $uibModal,
    $translate,
    $sce,
    $log,
    $httpParamSerializer,
    $state,
    $stateParams,
    $timeout,
    aidesService,
    demandesAidesService,
    partageAidesService,
    configuration,
    accountManagementService,
    userSessionService,
    jwtSessionService,
    StoreService,
    types,
    routeUtilsService,
    jwtSessionStorage,
    sessionServiceAPI,
    AUTHENTICATION_EVENTS
  ) {
    this._scope = $scope;
    this._rootScope = $rootScope;
    this._modal = $modal;
    this._uibModal = $uibModal;
    this._sce = $sce;
    this._log = $log;
    this._httpParamSerializer = $httpParamSerializer;
    this._state = $state;
    this._translate = $translate;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
    this.StoreService = StoreService;
    this.aidesService = aidesService;
    this.demandesAidesService = demandesAidesService;
    this.partageAidesService = partageAidesService;
    this.types = types;
    this.routeUtilsService = routeUtilsService;
    this.currentUser = userSessionService.getUser();
    this.jwtSessionStorage = jwtSessionStorage;
    this.sessionServiceAPI = sessionServiceAPI;
    this.AUTHENTICATION_EVENTS = AUTHENTICATION_EVENTS;

    /**
     * Show alert (using notification center)
     *
     * @param {string} type
     * @param {string} msgKey
     * @param {object} msgOptions
     * @param {undefined | number} delay
     * @returns {void}
     */
    function showAlert(type, msgKey, msgOptions, delay) {
      NotificationCenter.postMessage({
        variant: type === 'error' ? 'danger' : type,
        content: $translate.instant(msgKey, msgOptions),
        delay,
      });
    }

    if ($stateParams.unauthorized) {
      showAlert(types.AlertTypes.ERROR, 'connected.dashboard.aides.errors.incompatible-tiers');
    } else if ($stateParams.teleServiceNotFound) {
      showAlert(types.AlertTypes.ERROR, 'teleservice.errors.notFound');
    } else if ($stateParams.maximumPersistenceReach) {
      this.modalMaxNumberPersistenceReach();
    } else if ($stateParams.maximumOffresStageReached) {
      this.modalMaxNumberOffresPersistenceReached();
    } else if ($stateParams.teleserviceNotInvited) {
      showAlert(types.AlertTypes.WARNING, 'teleservice.errors.notInvited', {
        libelleTeleservice: $stateParams.teleserviceNotInvited,
      });
    } else if (StoreService.dashboardAlert.get()) {
      this.alerts = StoreService.dashboardAlert.get();
      StoreService.dashboardAlert.clear();
    }

    // we get the redirectParams parameter and we parse it
    const redirectParams = this.getParam('redirectParams') || '{}';
    let parsed;
    try {
      parsed = JSON.parse(redirectParams);
    } catch (err) {
      parsed = {};
    }
    const actionToken = $stateParams.token || parsed.token;
    const email = $stateParams.email || parsed.email;

    // Skip email modification confirmation process when login oidc provider
    // franceConnect Users have a providerId as well so we need to specifically allow them
    if (this.currentUser?.franceConnect || !this.currentUser?.providerId) {
      // The user clicked in the link which was in the email modification confirmation email
      if (actionToken && email && this.currentUser?.id) {
        const { id: userId } = userSessionService.getUser();
        accountManagementService
          .validateEmail(userId, email, actionToken)
          .then(() => {
            // user is logged out after confirmed his email updates
            this.sessionServiceAPI.getToken().then((token) => {
              this.sessionServiceAPI.logout().then(() => {
                // Pass the token trought the logout event so the portail controller can deal with it
                this._rootScope.$broadcast(this.AUTHENTICATION_EVENTS.logoutSuccess, {
                  token,
                  successKey: 'email-confirm',
                });
              });
            });
          })
          .catch((error) => {
            if (error.status === 404) {
              showAlert(
                types.AlertTypes.ERROR,
                'user.account-email-modification-confirmation.errors.validation-failure-404'
              );
            } else {
              showAlert(
                types.AlertTypes.ERROR,
                'user.account-email-modification-confirmation.errors.validation-failure-other'
              );
            }
          });
      } else {
        accountManagementService.emailModificationPending(userSessionService.getUser().id).then((response) => {
          if (response.data.status === 'VALIDATION_IN_PROGRESS') {
            showAlert(
              types.AlertTypes.WARNING,
              'user.account-update-email.email-validation-pending',
              {
                email: response.data.email,
              },
              5
            );
          }
        });
      }
    }
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    const dashboardIframeQueryParams = this._httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: 'current-tiers-ref',
    });

    const templateDashboardIframeSrc = `${this.configuration.ux}<%= tenantId %>/home-view?<%= queryParams %>`;
    const compiledDashboardIframeSrc = _.template(templateDashboardIframeSrc);
    const dashboardIframeSrc = compiledDashboardIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: dashboardIframeQueryParams,
    });

    this.dashboardIframeSrc = this._sce.trustAsResourceUrl(dashboardIframeSrc);
  }

  /**
   * Open max number demandes reached modal
   *
   * @returns {void}
   */
  modalMaxNumberPersistenceReach() {
    const scopeModal = this._scope.$new();
    this._modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-maxnumber-demandes.html',
    });
  }

  /**
   * Open max number stages reached modal
   *
   * @returns {void}
   */
  modalMaxNumberOffresPersistenceReached() {
    const scopeModal = this._scope.$new();
    this._modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-maxnumber-offresstage.html',
    });
  }

  /**
   * Return a parameter value from the current URL
   * we use this method because we can't access to url parameters via $location or $stateParams
   *
   * @param {string} sname parameter to search
   * @returns {void}
   */
  getParam(sname) {
    let params = location.href.substr(location.href.indexOf('?') + 1);
    let sval = '';
    params = params.split('&');
    // split param and value into individual pieces
    for (let i = 0; i < params.length; i++) {
      const temp = params[i].split('=');
      if (temp[0] === sname) {
        sval = temp[1];
      }
    }
    return decodeURIComponent(sval);
  }
}

angular.module('portailDepotDemandeAide.dashboard').component('dashboard', {
  controller: DashboardController,
  templateUrl: 'dashboard/dashboard.component.html',
});

DashboardController.$inject = [
  '$rootScope',
  '$scope',
  '$modal',
  '$uibModal',
  '$translate',
  '$sce',
  '$log',
  '$httpParamSerializer',
  '$state',
  '$stateParams',
  '$timeout',
  'aidesService',
  'demandesAidesService',
  'partageAidesService',
  'configuration',
  'accountManagementService',
  'userSessionService',
  'jwtSessionService',
  'StoreService',
  'types',
  'routeUtilsService',
  'jwtSessionStorage',
  'sessionServiceAPI',
  'AUTHENTICATION_EVENTS',
];
