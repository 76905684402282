'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementDomiciliationBancaireController', [
  '$document',
  '$scope',
  '$q',
  '$modal',
  'aidesService',
  'demandesPaiementService',
  'configuration',
  function ($document, $scope, $q, $modal, aidesService, demandesPaiementService, configuration) {
    'use strict';

    $scope.initializeChild();

    $scope.currentlyClickingNextBtn = false;
    $scope.pieceUploadInProgress = false;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'domiciliationBancaire';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = false;
    $scope.navigate.ns = $scope.domiciliationBancaireConfiguration.ns;

    $scope.navigate.next = function (forget) {
      $scope.currentlyClickingNextBtn = false;
      if ($scope.pieceUploadInProgress) {
        $scope.currentlyClickingNextBtn = true;
      } else {
        // Copy domiciliation bancaire document
        if (!$scope.createDom && $scope.selectedDom >= 0) {
          // gestion en promesse pour que l'on push les modifications du documents suite a la copie
          var promisesCopyDocumentDomiciliationBancaireTiers = [];
          _.each($scope.demandePaiement.domiciliationBancaire.pieces, function (piece) {
            _.each(piece.documents, function (documentPiece) {
              promisesCopyDocumentDomiciliationBancaireTiers.push(
                aidesService.copyDocumentDomiciliationBancaireTiers(
                  $scope.urlDocuments,
                  documentPiece,
                  'demandePaiement',
                  $scope.demandePaiement
                )
              );
            });
          });
          $q.all(promisesCopyDocumentDomiciliationBancaireTiers).then(function () {
            $scope.save();
          });
        }
        // The user created a new domiciliation but had selection displayed
        else if ($scope.showDomiciliations) {
          // Search for a correlation in tier's domiciliations
          var compareTo = $scope.demandePaiement.domiciliationBancaire.horsSEPA ? 'horsSEPA[0].valeur' : 'IBAN.numero';
          var correlationFound = _.findIndex(
            _.filter($scope.tiers.domiciliationsBancaires, { actif: true }),
            function (domiciliation) {
              return _.get(domiciliation, compareTo) === _.get($scope.demandePaiement.domiciliationBancaire, compareTo);
            }
          );

          // If a correlation is found, alert user that tiers' already has this domiciliation
          if (correlationFound > -1) {
            // displays confirmation Modal
            var scopeModal = $scope.$new();

            scopeModal.namespace = $scope.navigate.ns;
            // On validation, select the tiers' domiciliation
            scopeModal.validate = function (modal) {
              modal.$hide();
            };

            $modal({
              scope: scopeModal,
              template: 'depot/demande-paiement/domiciliation-bancaire/modal/correlation-confirm.html',
            });

            // Display the modal without going to next page
            return;
          }
        }

        // Go to next page
        $scope.goToStep('pieces', forget);
      }
    };

    // Page Options
    $scope.pageOptions = _.get($scope.teleserviceConfiguration, 'workflow.pageDomiciliationBancaire', {});
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    // Url documents
    $scope.urlDocuments = $scope.demandePaiement._links['mgs:documents'].href;

    /**
     * Manage the action after the upload of pieces
     */
    $scope.saveDemandePaiementOnDomiciliationPiecesUpload = function () {
      const domiciliationBancaire = $scope.demandePaiement.domiciliationBancaire;
      // If no data in form do not save it and keep only the pieces
      if (
        !domiciliationBancaire?.IBAN?.numero &&
        !domiciliationBancaire?.horsSEPA?.find(({ cle }) => cle === 'numero')?.valeur
      ) {
        const patches = [
          {
            op: 'add',
            path: '/domiciliationBancaire',
            value: { pieces: $scope.demandePaiement.domiciliationBancaire.pieces },
          },
        ];
        demandesPaiementService
          .patchDemandePaiement($scope.demandePaiement.reference, patches)
          .then((demandePaiement) => {
            $scope.originalDemandePaiement = demandePaiement;
          });
      } else {
        $scope.save();
      }
    };

    // Validation Banque de France
    $scope.bdfFile = _.get(configuration, 'demande-paiement.banqueDeFrance');
    $scope.validationIbanFr = $scope.bdfFile && _.get(configuration, 'demande-paiement.validationIbanFr');

    // Domiciliation defined by user
    var newDomiciliation;

    // Add persistenceConfiguration to allowedExtensions
    $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand') || {};

    /**
     * Select domiciliation from existing one
     *
     * @param  {number} index Index of domiciliations array
     */
    $scope.selectDomiciliation = function (index) {
      // If we leave the creation form, we keep the data somewhere
      if (
        (!$scope.showDomiciliations || $scope.createDom) &&
        $scope.demandePaiement.domiciliationBancaire &&
        !$scope.demandePaiement.domiciliationBancaire.id
      ) {
        newDomiciliation = $scope.demandePaiement.domiciliationBancaire;
      }
      $scope.demandePaiement.domiciliationBancaire = $scope.domiciliations[index];
      $scope.selectedDom = index;
      $scope.createDom = false;
    };

    /**
     * Return the horsSEPA item containing the IBAN
     *
     * @param {object[]} horsSEPA The value of horsSEPA domiciliation
     * @returns {object} The horsSEPA item containing the IBAN
     */
    const getIbanFromHorsSEPA = (horsSEPA) => {
      return horsSEPA.find((horsSepaItem) => horsSepaItem.cle === 'numero');
    };

    /**
     * Return true if the 2 domiciliations bancaires are equivalent
     *
     * @param {object} domiciliation1 A domiciliation bancaire
     * @param {object} domiciliation2 A domiciliation bancaire
     * @returns {boolean} True if the 2 domiciliations bancaires are equivalent
     */
    const isTheSameDomiciliation = (domiciliation1, domiciliation2) => {
      // Not the same mode
      if (!domiciliation1 || !domiciliation2 || !!domiciliation1.horsSEPA !== !!domiciliation2.horsSEPA) {
        return false;
      }

      // Check IBAN
      if (!domiciliation1.horsSEPA) {
        return domiciliation1.IBAN.numero === domiciliation2.IBAN.numero;
      }

      const domiciliation1Iban = getIbanFromHorsSEPA(domiciliation1.horsSEPA);
      const domiciliation2Iban = getIbanFromHorsSEPA(domiciliation2.horsSEPA);

      return domiciliation1Iban?.valeur === domiciliation2Iban?.valeur;
    };

    /**
     * Return a domiciliation of the tiers attributaire
     *
     * @param {object} params parameters
     * @param {object[]} [params.domiciliationsAttributaire = []] The domiciliations bancaires of the tiers attributaire
     * @param {object} params.domiciliationOfDemandeFinancement The domiciliation bancaire used in the demande-financement
     * @returns {object | undefined} The domiciliation of the tiers attrributaire to display
     */
    const selectDomiciliationFromAttributaire = ({
      domiciliationsAttributaire = [],
      domiciliationOfDemandeFinancement,
    }) => {
      const domiciliationsOfAttributaireActif = domiciliationsAttributaire.filter(({ actif }) => actif);
      if (!domiciliationsOfAttributaireActif.length) {
        return;
      }

      // Select the domiciliation of the tiers attributaire on the demande financement
      const domiciliationOfAttributaireOnDemandeFinancement = domiciliationsOfAttributaireActif.find(
        (domiciliationAttributaire) => {
          return isTheSameDomiciliation(domiciliationAttributaire, domiciliationOfDemandeFinancement);
        }
      );
      if (domiciliationOfAttributaireOnDemandeFinancement) {
        return domiciliationOfAttributaireOnDemandeFinancement;
      }

      // Select the domiciliation principale
      const domiciliationPrincipaleOfAttributaire = domiciliationsOfAttributaireActif.find(
        ({ principale }) => principale
      );
      if (domiciliationPrincipaleOfAttributaire) {
        return domiciliationPrincipaleOfAttributaire;
      }

      // Select the only domiciliation of tiers attributaire
      if (domiciliationsOfAttributaireActif.length === 1) {
        return domiciliationsOfAttributaireActif[0];
      }

      return;
    };

    /**
     * Merge the domiciliation of the attributaire with the domiciliations of the tiers
     *
     * @param {object} params parameters
     * @param {object[]} [params.domiciliationsTiers = []] The domiciliations of the tiers to sort
     * @param {object} params.domiciliationToAddFirst The domicliation to add at index 0
     * @returns {object[]} The domiciliations sorted with the one to add at first index if defined
     */
    const mergeAndSortDomiciliations = ({ domiciliationsTiers = [], domiciliationToAddFirst }) => {
      const domiciliationsBeneficiaireOrdered = domiciliationsTiers
        .filter((domiciliation) => domiciliation.actif)
        .sort((domiciliation1, domiciliation2) => !!domiciliation2.principale - !!domiciliation1.principale);

      if (!domiciliationToAddFirst) {
        return domiciliationsBeneficiaireOrdered;
      }

      const domiciliations = [];
      domiciliations.push(domiciliationToAddFirst);

      // Avoid to have 2 times the same domiciliation when tiers is the attributaire
      const domiciliationsDifferentFromTheOneAdded = domiciliationsBeneficiaireOrdered.filter(
        (domiciliation) => domiciliation.id !== domiciliationToAddFirst.id
      );
      domiciliations.push(...domiciliationsDifferentFromTheOneAdded);

      return domiciliations;
    };

    // Fetch the domiciliation of the attributaire to display
    const domiciliationsAttributaire = $scope.demandePaiement?.attributaire?.expand?.domiciliationsBancaires;
    let domiciliationBancaireOfAttributaireToDisplay = selectDomiciliationFromAttributaire({
      domiciliationsAttributaire,
      domiciliationOfDemandeFinancement: $scope.demandePaiement?.demandeFinancement?.expand?.domiciliationBancaire,
    });

    // Sort, filter the tiers domiciliations and add the domiciliation of the attributaire
    $scope.domiciliations = mergeAndSortDomiciliations({
      domiciliationsTiers: $scope.tiers?.domiciliationsBancaires,
      domiciliationToAddFirst: domiciliationBancaireOfAttributaireToDisplay,
    });

    $scope.createDomiciliation = function () {
      $scope.demandePaiement.domiciliationBancaire = newDomiciliation;
      $scope.selectedDom = -1;
      $scope.createDom = true;
    };

    // If beneficiaire is user's tiers, and it has domiciliations, the user can pick one of them
    $scope.showDomiciliations =
      $scope.pageOptions.actif &&
      [
        $scope.demandePaiement?.attributaire?.href,
        $scope.demandePaiement?.demandeFinancement?.expand?.beneficiaires?.[0]?.href,
      ].includes($scope.tiers.id) &&
      $scope.domiciliations.length;

    // By default, we're in "creation mode"
    $scope.createDom = true;
    $scope.selectedDom = -1;

    // If there are domiciliations, we try to select one
    if ($scope.showDomiciliations && $scope.domiciliations?.length) {
      let selectedIndex;
      // Select the one of the demande-paiement
      if ($scope.demandePaiement.domiciliationBancaire) {
        selectedIndex = $scope.domiciliations.findIndex(
          (domiciliation) => domiciliation.id === $scope.demandePaiement.domiciliationBancaire.id
        );
      } else if (domiciliationBancaireOfAttributaireToDisplay) {
        // Preselect the domiciliation of the attributaire
        selectedIndex = 0;
      }

      // The first time the page is loaded, the domiciliation wanted is selected
      if (selectedIndex > -1) {
        $scope.selectDomiciliation(selectedIndex);
      }
    }

    // Principale domiciliation has a status that show a label
    $scope.principaleStatus = { label: $scope.navigate.ns + '.list.item.principal', class: 'success' };

    $scope.onBeforeUploadPiece = function (updatingDescription = false) {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.navigate.lockNavigation();
      }
    };

    $scope.onAfterUploadPiece = function (updatingDescription = false, success = false) {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.navigate.unlockNavigation();
      }
      if ($scope.currentlyClickingNextBtn) {
        $scope.currentlyClickingNextBtn = false;
        if (success) {
          $scope.navigate.next();
        }
      }
    };

    /**
     * Check the validity of a demande-paiement property based on the configuration made on a field
     *
     * @param {object} demandePaiement The demandePaiement to check pattern validity for
     * @param {object} fieldConfigration The field configuration with pattern and the path of the data to check on demand-paiement
     * @returns {boolean} True if the field pattern match the data or no data or no pattern defined
     */
    $scope.checkFieldConfiguration = function (demandePaiement, fieldConfigration) {
      const value = _.get(demandePaiement, fieldConfigration.path.value);
      if (!fieldConfigration.pattern || _.isEmpty(value)) {
        return true;
      }
      const regex = new RegExp(fieldConfigration.pattern);

      return regex.test(value);
    };

    $scope.sharedWithChild.automaticSaveActive = true;

    /**
     * Calculate the patches to make on an automatic update.
     * Remove or replace the fields if not matching pattern or error in input component
     *
     * @param {object} params parameters
     * @param {object[]} params.patches The list of patches to use
     * @returns {object[]} the list of patches to use for an automatic update
     */
    $scope.sharedWithChild.calculatePatchesForAutomaticUpdate = function ({ patches = [] }) {
      if (!patches.length || $scope.selectedDom >= 0) {
        // It's domiciliation already linked to a tiers
        return patches;
      }

      const demandePaiementCopyForDomiciliationUpdate = angular.copy($scope.demandePaiement);

      Object.values($scope.domiciliationBancaireConfiguration.fields).map(({ path, pattern }) => {
        const isPatternValid = $scope.checkFieldConfiguration(demandePaiementCopyForDomiciliationUpdate, {
          path,
          pattern,
        });
        if (!isPatternValid) {
          const oldValue = _.get($scope.originalDemandePaiement, path.value);
          _.set(demandePaiementCopyForDomiciliationUpdate, path.value, oldValue);
        }
      });

      const modeSEPA = demandePaiementCopyForDomiciliationUpdate?.domiciliationBancaire?.IBAN;
      if (modeSEPA) {
        const mgInputTextFields = Array.from($document[0].getElementsByTagName('mg-input-text'));

        if (modeSEPA.numero) {
          const ibanField = mgInputTextFields.find((div) => div.getAttribute('identifier') === 'iban');
          if (ibanField?.invalid) {
            delete modeSEPA.numero;
          }
        }

        if (modeSEPA.BIC) {
          const bicField = mgInputTextFields.find((div) => div.getAttribute('identifier') === 'iban-bic');
          if (bicField?.invalid) {
            delete modeSEPA.BIC;
          }
        }
      }

      if (demandePaiementCopyForDomiciliationUpdate?.domiciliationBancaire?.actif) {
        delete demandePaiementCopyForDomiciliationUpdate.domiciliationBancaire.actif;
      }

      const patchesListUpdated = demandesPaiementService.retrieveUpdatePatches({
        demandePaiementUpdated: demandePaiementCopyForDomiciliationUpdate,
        originalDemandePaiement: $scope.originalDemandePaiement,
      });

      return patchesListUpdated;
    };
  },
]);
